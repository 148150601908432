import React, { useState, useContext, useEffect, useMemo } from 'react';
import * as S from './AddToCartButtonV4.styles';
import { addToCart } from '../../utils/addToCart';
import FunnelContext from '../../context/FunnelContext';
import { Builder } from '@builder.io/react';
import CrossSellPopup from './CrossSellPopup';
import { addToSubscription } from '../../utils/activeSubscriptions';
import { navigate } from 'gatsby';
import useLocalStorage from '../../hooks/useLocalStorage';

const AddToCartButton = props => {
  const {
    buttonText,
    children,
    triggerCheckoutButton,
    overrideColors,
    textColor,
    backgroundColor,
    backgroundColorHover,
    crossSellEndpointApiUrl
  } = props;

  const sendToStagingCheckout = process.env.GATSBY_FUNNEL_STAGING;
  const overrideStagingCheckoutUrl =
    process.env.GATSBY_CHECKOUT_API_URL_STAGING;
  const stagingCheckoutUrls = useMemo(
    () =>
      process.env.GATSBY_FUNNEL_STAGING_URLS?.split('|') || [
        process.env.GATSBY_CHECKOUT_API_URL_STAGING
      ],
    []
  );

  const context = useContext(FunnelContext);

  const {
    upsell: upsellUrl,
    currency,
    onetime,
    subscription,
    currentCategory,
    setCurrentCategory,
    currentProduct,
    setCurrentProduct,
    path,
    pageDesign,
    bumpOfferChecked,
    setBumpOfferChecked,
    bumpOfferIndex,
    setBumpOfferIndex,
    frequency,
    setFrequency,
    setSubmittedToCart,
    tubIndexChecked,
    extraObjects,
    setExtraObjects
  } = context;
  const funnelUrl = window.location.href;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [crossSellConfirmed, setCrossSellConfirmed] = useState(false);

  const [, setCrossSellResponse] = useLocalStorage('cross-sell-response-data');
  const [extraObjects2] = useLocalStorage('extraObjects', {});
  const [addToCartButtonClicked, setAddToCartButtonClicked] = useLocalStorage(
    'add_to_cart_button_clicked',
    false
  );

  const [stagingCheckoutUrl, setStagingCheckoutUrl] = useState(
    overrideStagingCheckoutUrl
  );

  useEffect(() => {
    if (sendToStagingCheckout) {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop)
      });
      if (params) {
        setStagingCheckoutUrl(
          params.checkoutUrl || stagingCheckoutUrls[params.cko || 0]
        );
      }
    }
  }, [
    sendToStagingCheckout,
    setStagingCheckoutUrl,
    overrideStagingCheckoutUrl,
    stagingCheckoutUrls
  ]);

  useEffect(() => {
    setButtonDisabled(addToCartButtonClicked);
    setButtonClicked(addToCartButtonClicked);
    if (addToCartButtonClicked) {
      setTimeout(() => {
        setAddToCartButtonClicked(false);
      }, 5000);
    }
  }, [
    setButtonDisabled,
    setButtonClicked,
    addToCartButtonClicked,
    setAddToCartButtonClicked
  ]);

  useEffect(() => {
    const newExtraData = Object.assign(extraObjects, extraObjects2);
    setExtraObjects(newExtraData);
  }, [extraObjects, extraObjects2, setExtraObjects]);

  const triggerAddToCart = () => {
    if (Builder.isEditing) {
      return false;
    }

    if (buttonClicked) {
      return false;
    }

    setButtonDisabled(true);
    setButtonClicked(true);
    setSubmittedToCart(true);
    setAddToCartButtonClicked(true);

    const extraData = {
      ...extraObjects,
      currency_code: currency.code,
      currency_symbol: currency.symbol,
      trigger_checkout_button: triggerCheckoutButton,
      sendToStagingCheckout: sendToStagingCheckout ?? false,
      overrideStagingCheckoutUrl: stagingCheckoutUrl,
      setButtonDisabled,
      setButtonClicked,
      setSubmittedToCart,
      upsell_url: upsellUrl
    };

    if (bumpOfferChecked && extraData?.bump_offer_index_override) {
      setBumpOfferIndex(extraData.bump_offer_index_override);
    }

    if (tubIndexChecked && extraData?.tub_index_override) {
      const productPrefix =
        currentCategory === `onetime` ? `onetime` : `subscription`;
      setCurrentProduct(`${productPrefix}_${extraData?.tub_index_override}`);
    }

    let finalCurrentCategory = currentCategory;
    let finalCurrentProduct = currentProduct;
    let finalBumpOfferChecked = bumpOfferChecked;
    let finalBumpOfferIndex = bumpOfferIndex;
    let finalFrequency = frequency;

    // extraObjects overrides
    let extraObjectsOverride = extraObjects?.extra_objects_override ?? false;
    if (extraObjectsOverride) {
      if (extraObjects?.current_category_override) {
        const categoryOverride =
          extraObjects.current_category_override === 'onetime'
            ? 'onetime'
            : 'subscriptions';
        setCurrentCategory(categoryOverride);
        setExtraObjects(
          Object.assign(extraObjects, { currentCategory: categoryOverride })
        );
        finalCurrentCategory = categoryOverride;
      }
      if (extraObjects?.tub_index_override) {
        const productPrefix2 =
          finalCurrentCategory === `onetime` ? `onetime` : `subscription`;
        const productId = `${productPrefix2}_${extraObjects?.tub_index_override}`;
        setCurrentProduct(productId);
        finalCurrentProduct = productId;
      }
      if (extraObjects?.bump_offer_checked_override) {
        finalBumpOfferChecked =
          extraObjects.bump_offer_checked_override === '0' ? false : true;
        setBumpOfferChecked(finalBumpOfferChecked);
      }
      if (extraObjects?.bump_offer_index_override) {
        setBumpOfferIndex(extraObjects.bump_offer_index_override);
        finalBumpOfferIndex = extraObjects.bump_offer_index_override;
      }
      if (extraObjects?.delivery_frequency_override) {
        setFrequency(extraObjects.delivery_frequency_override);
        finalFrequency = extraObjects.delivery_frequency_override;
      }

      if (extraObjects?.multiple_bump_offer_index) {
        const bumpOffers1 =
          finalCurrentCategory === `onetime`
            ? onetime.bumpoffers
            : subscription.bumpoffers;
        const multiple_bump_offer = bumpOffers1.map((bumpOfferData, index) => {
          if (
            extraObjects?.multiple_bump_offer_index.includes(index.toString())
          ) {
            return bumpOfferData;
          }
          return null;
        });
        Object.assign(extraData, { multiple_bump_offer });
        finalBumpOfferChecked = false;
      }
    }

    try {
      if (finalCurrentCategory === 'subscriptions') {
        const { discounts, products, bumpoffers } = subscription;
        const bumpOfferData = finalBumpOfferChecked
          ? bumpoffers[finalBumpOfferIndex]
          : {};
        products.map((product, i) => {
          if (finalCurrentProduct === `subscription_${i}`) {
            product.schedule = finalFrequency;
            return addToCart(
              product,
              discounts,
              extraData,
              bumpOfferData,
              path,
              funnelUrl
            );
          }
          return null;
        });
      } else {
        const { discounts, products, bumpoffers } = onetime;
        const bumpOfferData = finalBumpOfferChecked
          ? bumpoffers[finalBumpOfferIndex]
          : {};
        products.map((product, i) => {
          if (finalCurrentProduct === `onetime_${i}`) {
            return addToCart(
              product,
              discounts,
              extraData,
              bumpOfferData,
              path,
              funnelUrl
            );
          }
          return null;
        });
      }
    } catch (err) {
      setButtonDisabled(false);
      setButtonClicked(false);
      setSubmittedToCart(false);
    }
  };

  const triggerAddToActiveSubscription = () => {
    try {
      setButtonDisabled(true);
      setButtonClicked(true);
      setSubmittedToCart(true);

      if (currentCategory === 'subscriptions') {
        const { products } = subscription;
        products.map(({ checkoutData }, index) => {
          if (currentProduct === `subscription_${index}`) {
            return addToSubscription({
              apiUrl: crossSellEndpointApiUrl,
              extra: extraObjects,
              checkoutData: checkoutData
            }).then(response => {
              setCrossSellResponse({
                ...response?.data,
                checkoutData: checkoutData
              });
              navigate(`${path}/confirmation`, {
                replace: true
              });
              setButtonDisabled(false);
              setButtonClicked(false);
              setSubmittedToCart(false);
            });
          }
          return null;
        });
      } else {
        const { products } = onetime;
        products.map(({ checkoutData }, index) => {
          if (currentProduct === `onetime_${index}`) {
            return addToSubscription({
              apiUrl: crossSellEndpointApiUrl,
              extra: extraObjects,
              checkoutData: checkoutData
            }).then(response => {
              setCrossSellResponse({
                ...response?.data,
                checkoutData: checkoutData
              });
              navigate(`${path}/confirmation`, {
                replace: true
              });
              setButtonDisabled(false);
              setButtonClicked(false);
              setSubmittedToCart(false);
            });
          }
          return null;
        });
      }
    } catch (err) {
      setButtonDisabled(false);
      setButtonClicked(false);
      setSubmittedToCart(false);
    }
  };

  const triggerOnClick = () => {
    if (Builder.isEditing) {
      return false;
    }

    switch (triggerCheckoutButton) {
      case 'update-active-subscription':
        return triggerAddToActiveSubscription();
      case 'cross-sell':
        if (extraObjects?.active_subscription_id) {
          setCrossSellConfirmed(true);
        }
        break;
      default:
      case 'none':
        return triggerAddToCart();
    }
  };

  const AddToCartButton = ({ children }) => {
    return (
      <S.AddToCartButton
        type="button"
        onClick={triggerOnClick}
        disabled={buttonDisabled ? 'disabled' : ''}
        className={`${buttonClicked ? 'processing' : ''} add-to-card-button`}
        pageDesign={pageDesign}
        textColor={textColor}
        backgroundColor={backgroundColor}
        backgroundColorHover={backgroundColorHover}
        overrideColors={overrideColors}
      >
        <span>{children}</span>
      </S.AddToCartButton>
    );
  };

  if (children) {
    return (
      <S.Container
        staging={sendToStagingCheckout || false}
        checkoutUrl={stagingCheckoutUrl}
      >
        <S.WithChildren
          type="button"
          onClick={triggerOnClick}
          disabled={buttonDisabled ? 'disabled' : ''}
          className={buttonClicked ? 'processing' : ''}
          textColor={textColor}
          backgroundColor={backgroundColor}
          backgroundColorHover={backgroundColorHover}
        >
          {children}
        </S.WithChildren>
        {triggerCheckoutButton === `cross-sell` && (
          <CrossSellPopup
            show={crossSellConfirmed}
            setShow={setCrossSellConfirmed}
            endpointUrl={crossSellEndpointApiUrl}
          />
        )}
      </S.Container>
    );
  } else {
    return (
      <S.Container
        staging={sendToStagingCheckout || false}
        checkoutUrl={stagingCheckoutUrl}
      >
        <AddToCartButton>{buttonText}</AddToCartButton>
        {triggerCheckoutButton === `cross-sell` && (
          <CrossSellPopup
            show={crossSellConfirmed}
            setShow={setCrossSellConfirmed}
            endpointUrl={crossSellEndpointApiUrl}
          />
        )}
      </S.Container>
    );
  }
};

export default AddToCartButton;
